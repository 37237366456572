import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import api from './api'
import AppRouter from './AppRouter'
import { useAuth0 } from './components/Auth0Provider/Auth0Provider'
import { standardizeToEasternTime } from './utilities/dateTimeUtils'

function App () {
  const { user } = useAuth0()
  const { email, relationship, userId } = user || {}
  useEffect(() => {
    const loginData = {
      event: 'Partner Login',
      properties: {
        Email: email,
        'Outlier ID': userId,
        School: relationship?.fields?.relationshipName,
        Timestamp: standardizeToEasternTime(new Date())
      }
    }

    api.submitTrackedEvent(loginData)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <AppRouter />
    </>
  )
}

export default App
